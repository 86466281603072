<template>
	<nav aria-label="Brødkrumme" class="c-page-breadcrumb">
		<!-- Desktop -->
		<ol
			:class="[
				'hidden >=1024:flex items-center gap-x-12 flex-wrap',
				'w-full',
			]"
			@click="(e) => e.stopPropagation()"
		>
			<li
				v-for="(item, index) in items.length < 5
					? items
					: items.slice(0, 2)"
				:key="`item-${index}`"
				class="flex gap-x-12"
			>
				<NuxtLinkExt
					:to="item.url"
					:target="item.target"
					:class="[
						'text-breadcrumb font-darker-grotesque font-medium mb-2',
						{ 'font-semibold': index === items.length - 1 },
					]"
					v-html="index === 0 ? 'Forside' : item.name"
				/>

				<SvgCaret
					v-if="index !== items.length - 1"
					:class="[
						'w-12 text-text-placeholder',
						'transform -rotate-90',
					]"
				/>
			</li>

			<template v-if="items.length >= 5">
				<AccordionGroup class="relative">
					<div class="relative">
						<AccordionHeader
							:id="`breadcrumb-${_uid}-desktop`"
							:key="`breadcrumb-${_uid}-desktop`"
							:aria-controls="`breadcrumb-panel-${_uid}-desktop`"
							aria-label="Flere brødkrumme punkter"
							:class="[
								'flex gap-x-3 py-10 px-12',
								'rounded-full border-2 border-black',
								'duration-300 ease-smooth-out',

								{
									'bg-black': isOpen,
								},
							]"
						>
							<div
								v-for="i in 3"
								:key="`dot-${i}`"
								:class="[
									'w-5 h-5 rounded-full',
									'duration-300 ease-smooth-out',

									{
										'bg-black': !isOpen,
										'bg-white': isOpen,
									},
								]"
							></div>
						</AccordionHeader>

						<div
							v-show="isOpen"
							:class="[
								'c-page-breadcrumb__dropdown-arrow',
								'absolute top-full left-1/2',
								'transform -translate-x-1/2',
							]"
						></div>
					</div>

					<AccordionPanel
						:id="`breadcrumb-panel-${_uid}-desktop`"
						:key="`breadcrumb-panel-${_uid}-desktop`"
						ref="panelDesktop"
						:class="[
							'c-page-breadcrumb__dropdown',
							'absolute left-1/2 z-30 py-16',
							'transform -translate-x-1/2',
							'border-2 border-black bg-white ',
							'rounded-12 w-260 min-w-max',
						]"
						tag="ol"
						@change="onPanelChange"
					>
						<li
							v-for="(item, subIndex) in itemsDesktopDropdown"
							:key="`item-${subIndex}`"
						>
							<NuxtLinkExt
								:to="item.url"
								:target="item.target"
								:class="[
									'text-breadcrumb font-darker-grotesque font-medium opacity-70',
									'flex items-center gap-x-10 pl-14 pt-6 pb-8 pr-20',
									'focus:bg-background focus:opacity-100 hover:bg-background hover:opacity-100',
								]"
							>
								<span v-html="item.name"></span>
							</NuxtLinkExt>
						</li>
					</AccordionPanel>
				</AccordionGroup>

				<SvgCaret
					class="w-12 text-text-placeholder transform -rotate-90"
				/>

				<!-- Remaining items -->
				<li
					v-for="(item, index) in itemsDesktopAfter"
					:key="`item-after-${index}`"
					class="flex gap-x-12"
				>
					<NuxtLinkExt
						:to="item.url"
						:target="item.target"
						:class="[
							'text-breadcrumb font-darker-grotesque font-medium mb-2',
							{
								'font-semibold':
									index === itemsDesktopAfter.length - 1,
							},
						]"
						v-html="item.name"
					/>

					<SvgCaret
						v-if="index !== itemsDesktopAfter.length - 1"
						:class="[
							'w-12 text-text-placeholder',
							'transform -rotate-90',
						]"
					/>
				</li>
			</template>
		</ol>

		<!-- Mobile -->
		<div
			class="relative >=1024:hidden flex items-center gap-x-8 max-w-fit"
			@click="(e) => e.stopPropagation()"
		>
			<NuxtLinkExt
				:to="items[0].url"
				:target="items[0].target"
				:class="[
					'text-breadcrumb font-darker-grotesque font-semibold',
					'pt-4 pb-8 px-12 rounded-full border-2 border-black',
				]"
				v-text="'Forside'"
			/>

			<SvgCaret
				v-if="items.length > 2"
				class="w-12 text-text-placeholder transform -rotate-90"
			/>

			<AccordionGroup v-if="items.length > 2">
				<div class="relative">
					<AccordionHeader
						:id="`breadcrumb-${_uid}-mobile`"
						:key="`breadcrumb-${_uid}-mobile`"
						:aria-controls="`breadcrumb-panel-${_uid}-mobile`"
						aria-label="Flere brødkrumme punkter"
						:class="[
							'flex gap-x-3 py-10 px-12',
							'rounded-full border-2 border-black',
							'duration-300 ease-smooth-out',

							{
								'bg-black': isOpen,
							},
						]"
					>
						<div
							v-for="i in 3"
							:key="`dot-${i}`"
							:class="[
								'w-5 h-5 rounded-full',
								'duration-300 ease-smooth-out',

								{
									'bg-black': !isOpen,
									'bg-white': isOpen,
								},
							]"
						></div>
					</AccordionHeader>

					<div
						v-show="isOpen"
						:class="[
							'c-page-breadcrumb__dropdown-arrow',
							'absolute top-full left-1/2',
							'transform -translate-x-1/2',
						]"
					></div>
				</div>

				<AccordionPanel
					:id="`breadcrumb-panel-${_uid}-mobile`"
					:key="`breadcrumb-panel-${_uid}-mobile`"
					ref="panelMobile"
					:class="[
						'c-page-breadcrumb__dropdown',
						'absolute left-0 z-30 py-16',
						'border-2 border-black bg-white ',
						'rounded-12 min-w-5/5col',
					]"
					tag="ol"
					@change="onPanelChange"
				>
					<li
						v-for="(item, index) in items.slice(1, -1)"
						:key="`item-${index}`"
					>
						<NuxtLinkExt
							:to="item.url"
							:target="item.target"
							:class="[
								'text-breadcrumb font-darker-grotesque font-medium opacity-70',
								'flex items-center gap-x-10 pl-14 pt-6 pb-8 pr-20',
								'focus:bg-background focus:opacity-100 hover:bg-background hover:opacity-100',
							]"
						>
							<span v-html="item.name"></span>
						</NuxtLinkExt>
					</li>
				</AccordionPanel>
			</AccordionGroup>
		</div>
	</nav>
</template>

<script>
import {
	AccordionGroup,
	AccordionHeader,
	AccordionPanel,
} from '~/citi-baseline/components/Accordion';
import SvgCaret from '~/assets/svgs/icon-caret.svg?inline';

export default {
	name: 'PageBreadcrumb',

	components: {
		AccordionGroup,
		AccordionHeader,
		AccordionPanel,
		SvgCaret,
	},

	props: {
		items: {
			type: Array,
			default: () => [],
		},
	},

	data() {
		return {
			isOpen: false,
		};
	},

	computed: {
		itemsDesktopDropdown() {
			if (this.items.length < 5) {
				return [];
			}
			return this.items.slice(2, -2);
		},
		itemsDesktopAfter() {
			if (this.items.length < 5) {
				return [];
			}
			return this.items.slice(-2);
		},
	},

	mounted() {
		window.addEventListener('click', this.closePanel);
	},

	beforeDestroy() {
		window.removeEventListener('click', this.closePanel);
	},

	methods: {
		onPanelChange(event) {
			this.isOpen = event.isOpen;
			if (this.$refs?.panelMobile) {
				this.isOpen
					? this.$refs.panelMobile.open()
					: this.$refs.panelMobile.close();
			}
			if (this.$refs?.panelDesktop) {
				this.isOpen
					? this.$refs.panelDesktop.open()
					: this.$refs.panelDesktop.close();
			}
		},

		closePanel() {
			if (this.$refs?.panelMobile) {
				this.$refs.panelMobile.close();
			}
			if (this.$refs?.panelDesktop) {
				this.$refs.panelDesktop.close();
			}
		},
	},
};
</script>

<style lang="postcss">
.c-page-breadcrumb__dropdown-arrow {
	border: 10px solid transparent;
	border-bottom: 10px solid #000;
}

.c-page-breadcrumb__dropdown {
	top: calc(100% + 18px);

	& path {
		stroke-width: 3px;
	}
}
</style>
