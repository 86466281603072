var render = function render(){var _vm=this,_c=_vm._self._c;return _c('nav',{staticClass:"c-page-breadcrumb",attrs:{"aria-label":"Brødkrumme"}},[_c('ol',{class:[
			'hidden >=1024:flex items-center gap-x-12 flex-wrap',
			'w-full',
		],on:{"click":(e) => e.stopPropagation()}},[_vm._l((_vm.items.length < 5
				? _vm.items
				: _vm.items.slice(0, 2)),function(item,index){return _c('li',{key:`item-${index}`,staticClass:"flex gap-x-12"},[_c('NuxtLinkExt',{class:[
					'text-breadcrumb font-darker-grotesque font-medium mb-2',
					{ 'font-semibold': index === _vm.items.length - 1 },
				],attrs:{"to":item.url,"target":item.target},domProps:{"innerHTML":_vm._s(index === 0 ? 'Forside' : item.name)}}),_vm._v(" "),(index !== _vm.items.length - 1)?_c('SvgCaret',{class:[
					'w-12 text-text-placeholder',
					'transform -rotate-90',
				]}):_vm._e()],1)}),_vm._v(" "),(_vm.items.length >= 5)?[_c('AccordionGroup',{staticClass:"relative"},[_c('div',{staticClass:"relative"},[_c('AccordionHeader',{key:`breadcrumb-${_vm._uid}-desktop`,class:[
							'flex gap-x-3 py-10 px-12',
							'rounded-full border-2 border-black',
							'duration-300 ease-smooth-out',

							{
								'bg-black': _vm.isOpen,
							},
						],attrs:{"id":`breadcrumb-${_vm._uid}-desktop`,"aria-controls":`breadcrumb-panel-${_vm._uid}-desktop`,"aria-label":"Flere brødkrumme punkter"}},_vm._l((3),function(i){return _c('div',{key:`dot-${i}`,class:[
								'w-5 h-5 rounded-full',
								'duration-300 ease-smooth-out',

								{
									'bg-black': !_vm.isOpen,
									'bg-white': _vm.isOpen,
								},
							]})}),0),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isOpen),expression:"isOpen"}],class:[
							'c-page-breadcrumb__dropdown-arrow',
							'absolute top-full left-1/2',
							'transform -translate-x-1/2',
						]})],1),_vm._v(" "),_c('AccordionPanel',{key:`breadcrumb-panel-${_vm._uid}-desktop`,ref:"panelDesktop",class:[
						'c-page-breadcrumb__dropdown',
						'absolute left-1/2 z-30 py-16',
						'transform -translate-x-1/2',
						'border-2 border-black bg-white ',
						'rounded-12 w-260 min-w-max',
					],attrs:{"id":`breadcrumb-panel-${_vm._uid}-desktop`,"tag":"ol"},on:{"change":_vm.onPanelChange}},_vm._l((_vm.itemsDesktopDropdown),function(item,subIndex){return _c('li',{key:`item-${subIndex}`},[_c('NuxtLinkExt',{class:[
								'text-breadcrumb font-darker-grotesque font-medium opacity-70',
								'flex items-center gap-x-10 pl-14 pt-6 pb-8 pr-20',
								'focus:bg-background focus:opacity-100 hover:bg-background hover:opacity-100',
							],attrs:{"to":item.url,"target":item.target}},[_c('span',{domProps:{"innerHTML":_vm._s(item.name)}})])],1)}),0)],1),_vm._v(" "),_c('SvgCaret',{staticClass:"w-12 text-text-placeholder transform -rotate-90"}),_vm._v(" "),_vm._l((_vm.itemsDesktopAfter),function(item,index){return _c('li',{key:`item-after-${index}`,staticClass:"flex gap-x-12"},[_c('NuxtLinkExt',{class:[
						'text-breadcrumb font-darker-grotesque font-medium mb-2',
						{
							'font-semibold':
								index === _vm.itemsDesktopAfter.length - 1,
						},
					],attrs:{"to":item.url,"target":item.target},domProps:{"innerHTML":_vm._s(item.name)}}),_vm._v(" "),(index !== _vm.itemsDesktopAfter.length - 1)?_c('SvgCaret',{class:[
						'w-12 text-text-placeholder',
						'transform -rotate-90',
					]}):_vm._e()],1)})]:_vm._e()],2),_vm._v(" "),_c('div',{staticClass:"relative >=1024:hidden flex items-center gap-x-8 max-w-fit",on:{"click":(e) => e.stopPropagation()}},[_c('NuxtLinkExt',{class:[
				'text-breadcrumb font-darker-grotesque font-semibold',
				'pt-4 pb-8 px-12 rounded-full border-2 border-black',
			],attrs:{"to":_vm.items[0].url,"target":_vm.items[0].target},domProps:{"textContent":_vm._s('Forside')}}),_vm._v(" "),(_vm.items.length > 2)?_c('SvgCaret',{staticClass:"w-12 text-text-placeholder transform -rotate-90"}):_vm._e(),_vm._v(" "),(_vm.items.length > 2)?_c('AccordionGroup',[_c('div',{staticClass:"relative"},[_c('AccordionHeader',{key:`breadcrumb-${_vm._uid}-mobile`,class:[
						'flex gap-x-3 py-10 px-12',
						'rounded-full border-2 border-black',
						'duration-300 ease-smooth-out',

						{
							'bg-black': _vm.isOpen,
						},
					],attrs:{"id":`breadcrumb-${_vm._uid}-mobile`,"aria-controls":`breadcrumb-panel-${_vm._uid}-mobile`,"aria-label":"Flere brødkrumme punkter"}},_vm._l((3),function(i){return _c('div',{key:`dot-${i}`,class:[
							'w-5 h-5 rounded-full',
							'duration-300 ease-smooth-out',

							{
								'bg-black': !_vm.isOpen,
								'bg-white': _vm.isOpen,
							},
						]})}),0),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isOpen),expression:"isOpen"}],class:[
						'c-page-breadcrumb__dropdown-arrow',
						'absolute top-full left-1/2',
						'transform -translate-x-1/2',
					]})],1),_vm._v(" "),_c('AccordionPanel',{key:`breadcrumb-panel-${_vm._uid}-mobile`,ref:"panelMobile",class:[
					'c-page-breadcrumb__dropdown',
					'absolute left-0 z-30 py-16',
					'border-2 border-black bg-white ',
					'rounded-12 min-w-5/5col',
				],attrs:{"id":`breadcrumb-panel-${_vm._uid}-mobile`,"tag":"ol"},on:{"change":_vm.onPanelChange}},_vm._l((_vm.items.slice(1, -1)),function(item,index){return _c('li',{key:`item-${index}`},[_c('NuxtLinkExt',{class:[
							'text-breadcrumb font-darker-grotesque font-medium opacity-70',
							'flex items-center gap-x-10 pl-14 pt-6 pb-8 pr-20',
							'focus:bg-background focus:opacity-100 hover:bg-background hover:opacity-100',
						],attrs:{"to":item.url,"target":item.target}},[_c('span',{domProps:{"innerHTML":_vm._s(item.name)}})])],1)}),0)],1):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }